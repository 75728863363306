import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import PagePhotos from '../components/PagePhotos'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import ContainerBody from '../components/ContainerBody'

const PageTemplate = ({ data }) => {
  const { title, metaDescription, body, photos } = data.contentfulPage

  let ogImage
  try {
    ogImage = photos.ogimg.src
  } catch (error) {
    ogImage = null
  }
  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Container>
        <PageTitle>{title}</PageTitle>
        <PagePhotos photos={photos} />
        <ContainerBody>
          <PageBody body={body} />
        </ContainerBody>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      photos {
        title
        fluid(maxWidth: 900) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PageTemplate
