import React from 'react'
import styled from '@emotion/styled'
require('prismjs/themes/prism.css')

const Body = styled.div`
  margin-top: 50px;
  /* color: ${props => props.theme.colors.text}; */
  h1 {
    font-size : 2rem;
  }
  h2 {
    font-size : 1.5rem;
  }
`

const PageBody = props => {
  return (
    <>
      <Body
        dangerouslySetInnerHTML={{
          __html: props.body.childMarkdownRemark.html,
        }}
      />
    </>
  )
}
export default PageBody
