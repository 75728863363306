import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const BgImg = styled(Img)`
  border: 3px solid;
  border-radius: 50%;
  width: 20%;
  height: 20%;
  margin: 0 5%;
`

const PagePhotos = ({ photos }) => (
  <Wrapper>
    {photos.map((photo, key) => {
      return <BgImg key={key} fluid={photo.fluid} backgroundColor={'#eeeeee'} />
    })}
  </Wrapper>
)

export default PagePhotos
